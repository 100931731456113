import {Header} from "../Header/Header";
import {Menu} from "../Menu/Menu";

export const PageTemplate = ({children, selectedMenu}: any) => {
  return <div className={`min-h-[100vh] min-w-[980px]`}>
    <Header/>
    <div className={`flex min-h-[calc(100vh-70px)]`}>
      <div className={`min-w-[250px]`}>
        <Menu selectedMenu={selectedMenu}/>
      </div>
      <div className={`w-full overflow-auto p-[20px]`}>
        {children}
      </div>
    </div>
  </div>;
};
