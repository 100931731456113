import {PageTemplate} from "../../Components/PageTemplate/PageTemplate";
import {useEffect, useState, Fragment, useContext} from "react";
import {API_URL, ApiManager} from "../../Services/ApiManager";
import {ReactComponent as Close} from "../../Assets/Icons/close.svg";
import Moment from 'moment';
import {CONTENT_TYPE_CONTAINER, CONTENT_TYPE_INPUT_SELECT, CONTENT_TYPE_INPUT_TEXT, CONTENT_TYPE_TEXT, ContentCell} from "./ContentCell";
import {HeaderCell} from "./HeaderCell";
import {FilterBlock} from "./FilterBlock";
import {
  BRANDS,
  CAA_STATUS,
  CONFIGURATION_STATUS,
  COUNTRIES,
  ORDER_DIRECTION,
  ORDER_DIRECTION_ASC,
  ORDER_DIRECTION_DESC,
  PRIORITIES
} from "./Constants";
import {ReactComponent as GearIcon} from '../../Assets/Icons/gear.svg';
import {CommentModal} from './CommentModal';
import {FeaturesModal} from './FeaturesModal';
import {NotificationContextInterface, NotificationContext, ADD_NOTIFICATION_ACTION, NOTIFICATION_OPERATION_MERCHANT_STATUSES_UPDATED, NOTIFICATION_STATUS_SUCCESS, NOTIFICATION_STATUS_FAIL} from '../../Contexts/ToasterNotificationContext';

const GET_RETAILERS_URL = API_URL+'/brand-retailers';
const PUT_RETAILERS_URL = API_URL+'/brand-retailers/{retailerId}';
const UPDATE_CHECKER_URL = API_URL+'/checker/brand-retailers';
const GET_CELLS_SETTINGS = API_URL+'/settings/get/deactivatedCells';

const RESULT_LIMIT = 50;
const DEBOUNCE_TIMER = 500; // 500ms

export interface FiltersInterface {
  brand: string[];
  country: string[];
  caaStatus: string[];
  orderBy: FiltersOrderBy[];
  keyword: string;
}

export interface FiltersOrderBy {
  field: string;
  direction: ORDER_DIRECTION;
}

export interface Retailer {
  brandName: string;
  brandId: string;
  poolId: string;
  country: string;
  name: string;
  domains: string[];
  id: string;
  revenues: string;
  caaClicks: string;
  detection: string;
  lastChance: string;
  deeplink: string;
  caaStatus: string;
  comment: string;
  checkedAt: any;
  checkedBy: null|{
    firstname: string;
    lastname: string
  };
  priority: 'normal'|'high';
  isExtensionEnabled: boolean;
}

export interface FilterInterface {
  value: string;
  label: string;
  customClass?: string;
}

interface ResultsInterface {
  isLoading: boolean;
  hasMoreResults: boolean;
  data: Retailer[];
  counter: number;
}

export interface UpdateRetailerDataInterface {
  isExtensionEnabled: boolean;
}

export const RetailersPage = () => {
  const notificationContext = useContext<NotificationContextInterface>(NotificationContext);

  /** START Reload filters from url **/
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const countryParam = urlParams.get('country');
  const countries = countryParam === null ? [] : countryParam.split(',').filter((country) => country !== '');

  const brandParam = urlParams.get('brand');
  const brands = brandParam === null ? [] : brandParam.split(',').filter((country) => country !== '');

  const caaStatusParam = urlParams.get('caaStatus');
  const caaStatuses = caaStatusParam === null ? [] : caaStatusParam.split(',').filter((country) => country !== '');

  const orderByParams = urlParams.get('orderBy');
  const orderBy: FiltersOrderBy[] = orderByParams === null ? [] : orderByParams.split(',').map((order: string) => {
    if (order.length === 0) {
      return undefined;
    }

    const splittedData = order.split(':');
    if (splittedData.length !== 2 || splittedData[0].length === 0 || splittedData[1].length === 0) {
      return undefined;
    }

    return {
      field: splittedData[0],
      direction: splittedData[1]
    }
  }).filter((order: any) => order !== undefined) as FiltersOrderBy[];

  const keywordParam = urlParams.get('keyword') ?? undefined;
  /** END Reload filters from url **/

  const defaultFilters: FiltersInterface = {
    brand: brands,
    country: countries,
    caaStatus: caaStatuses,
    orderBy: orderBy,
    keyword: keywordParam ?? ''
  };

  const emptyFilters = {
    brand: [],
    country: [],
    caaStatus: [],
    orderBy: [],
    keyword: ''
  };

  const [loaders, setLoaders] = useState({
    shouldResetResults: false,
    shouldInfiniteScroll: false,
    shouldCellUpdate: false
  });

  const [results, setResults] = useState<ResultsInterface>({
    isLoading: false,
    hasMoreResults: true,
    data: [],
    counter: 0,
  });

  const [filters, setFilters] = useState<FiltersInterface>(defaultFilters);

  const [debouncedUpdateCell, setDebouncedUpdateCell] = useState<any>(undefined);
  const [updateCell, setUpdateCell] = useState<any>(undefined);

  const [debouncedSearchKeyword, setDebouncedSearchKeyword] = useState<any>(keywordParam);
  const [cellsSettings, setCellsSettings] = useState<{[name: string]: {[column: string]: boolean}}|undefined>(undefined);

  const [editingComment, setEditingComment] = useState<Retailer|undefined>(undefined);
  const [editingFeatures, setEditingFeatures] = useState<Retailer|undefined>(undefined);
  const [retailersToUpdateChecker, setRetailersToUpdateChecker] = useState<string[]>([]);
  const [displayUpdateCheckerButton, setDisplayUpdateCheckerButton] = useState<boolean>(false);
  const selectedBorders = ' border-[2px] border-green-500';

  const handleLastCheckerClick = (e: any) => {
    const brandRetailerId = e.target.dataset.brandRetailerId;
    if (!brandRetailerId) return;

    if (!retailersToUpdateChecker.includes(brandRetailerId)) {
      e.target.classList += selectedBorders;
      retailersToUpdateChecker.push(brandRetailerId);
    } else {
      e.target.className = e.target.className.replace(selectedBorders, '');
      retailersToUpdateChecker.splice(retailersToUpdateChecker.indexOf(brandRetailerId), 1);
    }

    setDisplayUpdateCheckerButton(retailersToUpdateChecker.length > 0);
    setRetailersToUpdateChecker(retailersToUpdateChecker);
  }

  function UpdateCheckerButton() {
    return <div>
      <div className={`cursor-pointer border-[2px] border-green-500 text-green-500 w-36 flex items-center justify-center rounded-xl mb-5`}
      onClick={updateLastChecker}>
        Update checker
      </div>
    </div>;
  }

  const updateLastChecker = () => {
    (async function () {
      try {
        const response = await ApiManager.put(UPDATE_CHECKER_URL, {
          retailersIds: retailersToUpdateChecker
        });

        response.data.forEach((retailer: any) => {
          const foundIndex = results.data.findIndex(x => x.id == retailer.id);
          results.data[foundIndex] = retailer;
          const checkerElement = document.getElementById('brandRetailerId'+retailer.id);
          if (checkerElement) checkerElement.className = checkerElement.className.replace(selectedBorders, '');;
        })

        setResults({
          ...results,
          isLoading: false,
          data: [...results.data],
          counter: response.data.count,
        });

        setRetailersToUpdateChecker([]);
        setDisplayUpdateCheckerButton(false);
      } catch (e: any) {
        console.error('Error while updating checkers', e);
      }
    })();
  }

  const handleClickCheckbox = (e: any) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const newFilters: FiltersInterface|any = {...filters};
    switch (e.target.name) {
      case 'country':
      case 'brand':
      case 'caaStatus':
        const indexOfValue = newFilters[inputName].indexOf(inputValue);
        if (indexOfValue === -1) {
          newFilters[inputName].push(inputValue);
        } else {
          newFilters[inputName].splice(indexOfValue, 1);
        }

        setFilters(newFilters);
        break;
      default:
        console.log('Filter behaviour not set for', e.target.name);
      break;
    }
  };

  async function handleInfiniteScroll() {
    const scrollTop = document.getElementById('results')?.scrollTop ?? 0;
    const scrollHeight = document.getElementById('results')?.scrollHeight ?? 0;
    const clientHeight = document.getElementById('results')?.clientHeight ?? 0;

    if (scrollTop + clientHeight >= scrollHeight - 20 && results.hasMoreResults && !loaders.shouldInfiniteScroll) {
      setLoaders({
        ...loaders,
        shouldInfiniteScroll: true
      });
    }
  }

  const handleOrderFilter = (fieldName: string) => {
    const existingOrderFilter = filters.orderBy.find((filter: FiltersOrderBy) => filter.field === fieldName);

    if (existingOrderFilter === undefined) {
      setFilters({
        ...filters,
        orderBy: [
          ...filters.orderBy,
          {
            field: fieldName,
            direction: ORDER_DIRECTION_ASC
          }
        ]
      })
    } else {
      const newOrders: (FiltersOrderBy|undefined)[] = filters.orderBy.map((order: FiltersOrderBy) => {
        if (order.field !== fieldName) {
          return order;
        }

        if (order.direction === ORDER_DIRECTION_ASC) {
          return {
            ...order,
            direction: ORDER_DIRECTION_DESC
          }
        }

        return undefined;
      });

      setFilters({
        ...filters,
        orderBy: newOrders.filter((order: FiltersOrderBy|undefined) => order !== undefined) as FiltersOrderBy[]
      });
    }
  };

  const handleChangeInput = (brandRetailerId: string, fieldname: string, value: string) => {
    setResults({
      ...results,
      data: results.data.map((retailer: Retailer) => {
        if (retailer.id !== brandRetailerId) {
          return retailer;
        }

        setDebouncedUpdateCell({
          brandRetailerId,
          fieldname,
          value
        });

        return {
          ...retailer,
          [fieldname]: value,
        }
      })
    });
  };

  const resetFilters = () => {
    setFilters(emptyFilters);
    setDebouncedSearchKeyword(undefined);
  };

  useEffect(() => {
    if ((loaders.shouldResetResults || loaders.shouldInfiniteScroll) && !results.isLoading) {
      if (loaders.shouldResetResults) {
        const element = document.getElementById('results');
        if (element !== null) {
          element.scrollTop = 0;
        }
      }

      (async function() {
        try {
          let newResults: ResultsInterface = {
            ...results,
            data: loaders.shouldInfiniteScroll ? [...results.data] : [],
            isLoading: true
          };

          setLoaders({
            ...loaders,
            shouldInfiniteScroll: false,
            shouldResetResults: false
          });

          const filtersToSend: any = {...filters};
          filtersToSend.orderBy = filters.orderBy.map((orderObject: FiltersOrderBy) => `${orderObject.field}:${orderObject.direction}`)

          const urlParams = new URLSearchParams(filtersToSend as any).toString().replace(new RegExp('%2C', 'g'), ',').replace(new RegExp('%3A', 'g'), ':');

          const currentFullUrlParams = `?${urlParams}&limit=${RESULT_LIMIT}&offset=${newResults.data.length}`;
          window.history.replaceState({}, '', `/retailers${currentFullUrlParams}`);

          const response = await ApiManager.get(`${GET_RETAILERS_URL}${currentFullUrlParams}`);

          newResults = {
            ...newResults,
            isLoading: false,
            hasMoreResults: response.data.retailers.length === RESULT_LIMIT,
            data: [...newResults.data, ...response.data.retailers],
            counter: response.data.count,
          };

          setResults(newResults);
        } catch(error: any) {
          setResults({...results, isLoading: false});
          console.error(`Error while fetching results:`, error);
        }
      })();
    }

    if (updateCell !== undefined) {
      setDebouncedUpdateCell(undefined);
      setResults({
        ...results,
        isLoading: true
      });
      (async function() {
        try {
          const response = await ApiManager.put(
            PUT_RETAILERS_URL.replace('{retailerId}', updateCell.brandRetailerId),
            {
              [updateCell.fieldname]: updateCell.value
            }
          );

          setResults({
            ...results,
            isLoading: false,
            data: results.data.map((retailer: Retailer) => {
              if (retailer.id !== updateCell.brandRetailerId) {
                return retailer;
              }

              return response.data;
            })
          });
          setUpdateCell(() => undefined);
        } catch (e: any) {
          console.error('Error while updating retailer data', e);
        }
      })();
    }
  }, [loaders, updateCell]);


  const updateRetailerData = (retailerId: string, dataToUpdate: UpdateRetailerDataInterface) => {
    const newState = {...results};
    newState.data.forEach((retailer: any) => {
      if (retailer.id === retailerId) {
        Object.keys(dataToUpdate).forEach((fieldName: string) => {
          // @ts-ignore
          retailer[fieldName] = dataToUpdate[fieldName];
        });
      }
    });

    setResults(() => ({...newState}));
  };

  const isCellActivated = (cellName: string, retailerName: string) => {
    if (!cellsSettings) return true;

    return cellsSettings[retailerName][cellName];
  };

  useEffect(() => {
    (async function() {
      try {
        const response = await ApiManager.get(GET_CELLS_SETTINGS);
        setCellsSettings(response.data?.value);
      } catch (e: any) {
        console.error('Error while getting settings', e);
      }
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('results')?.addEventListener('scroll', handleInfiniteScroll)
      return () => document.getElementById('results')?.removeEventListener('scroll', handleInfiniteScroll)
    }, 1000);
  }, []);

  useEffect(() => {
    setLoaders({
      ...loaders,
      shouldResetResults: true
    })
  }, [filters]);

  useEffect(() => {
    if (debouncedUpdateCell !== undefined) {
      const timer = setTimeout(() => setUpdateCell(debouncedUpdateCell), DEBOUNCE_TIMER);
      return () => clearTimeout(timer);
    }
  }, [debouncedUpdateCell]);

  useEffect(() => {
    if (undefined !== debouncedSearchKeyword && keywordParam !== debouncedSearchKeyword) {
      const timer = setTimeout(() => setFilters({...filters, keyword: debouncedSearchKeyword}), DEBOUNCE_TIMER);
      return () => clearTimeout(timer);
    }
  }, [debouncedSearchKeyword])

  return <PageTemplate selectedMenu={'retailers'}>
    {editingComment === undefined ? null :
      <CommentModal retailer={editingComment} closeModal={() => setEditingComment(undefined)} handleChangeInput={() => handleChangeInput(editingComment.id, 'comment', editingComment.comment)} onChangeValue={(e: any) => setEditingComment({...editingComment, comment: e.target.value})}/>
    }
    {editingFeatures === undefined ? null :
      <FeaturesModal retailer={editingFeatures} closeModal={() => setEditingFeatures(undefined)} updateRetailerData={updateRetailerData}/>
    }
    { displayUpdateCheckerButton && <UpdateCheckerButton />}
    <form>
      <div className={`h-[50px] flex items-center justify-between`}>
        <div className={`flex`}>
          <div className={`h-[40px] flex items-center justify-center mr-[10px]`}>
            Filter per
          </div>
          <div className={`h-[40px] ml-[10px] flex items-center`}>
            <input type={'text'} value={debouncedSearchKeyword ?? ''} onChange={(e: any) => setDebouncedSearchKeyword(() => e.target.value)} className={`h-full border-[1px] rounded-full pl-[10px] pr-[40px]`} placeholder={`Type keyword`}/>
            <div className={`ml-[-30px] mr-[30px] flex items-center w-[20px] h-[20px] border-[1px] rounded-full p-[2px]`} onClick={() => setDebouncedSearchKeyword('')}><Close width={`20px`}/></div>
          </div>
          <FilterBlock filterName={'country'} title={'Market'} handleClickCheckbox={handleClickCheckbox} currentFilterValue={filters.country} choices={COUNTRIES}/>
          <FilterBlock filterName={'brand'} title={'Brand'} handleClickCheckbox={handleClickCheckbox} currentFilterValue={filters.brand} choices={BRANDS} customClass={`w-[150px]`}/>
          <FilterBlock filterName={'caaStatus'} title={'CAA status'} handleClickCheckbox={handleClickCheckbox} currentFilterValue={filters.caaStatus} choices={CAA_STATUS} customClass={`w-[300px]`}/>
        </div>
        <div>
          <div className={`h-[40px] ml-[10px] flex justify-center items-center border-[1px] px-[10px] rounded-2xl cursor-pointer`} onClick={resetFilters}>
            <Close/> Reset filters
          </div>
        </div>
      </div>
    </form>
    <div className={`mt-[30px]`}>
      <div className={`h-[calc(100vh-240px)] overflow-auto border-t-[1px] text-sm`} id={`results`}>
        <div className={`grid grid-cols-[repeat(14,_minmax(130px,_1fr))] sticky z-20 top-0 bg-white`}>
          <HeaderCell title={'Retailer name'} fieldname={`name`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Market'} fieldname={`country`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Brand'} fieldname={`brandName`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Retailer url'} orderingList={filters.orderBy}/>
          <HeaderCell title={'Revenues'} fieldname={`revenues`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'CAA Imp.'} fieldname={`caaClicks`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Detection'} fieldname={`detection`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Last chance'} fieldname={`lastChance`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Deeplink'} fieldname={`deeplink`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'CAA status'} fieldname={`caaStatus`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Comment'} fieldname={`comment`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Priority'} fieldname={`priority`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Last check (UTC)'} fieldname={`checkedAt`} orderingList={filters.orderBy} handleOrderBy={handleOrderFilter}/>
          <HeaderCell title={'Last checker'} fieldname={`checkedBy`} orderingList={filters.orderBy} customClass={`border-r-[1px]`} handleOrderBy={handleOrderFilter}/>
        </div>
        <div className={`grid grid-cols-[repeat(14,_minmax(130px,_1fr))]`}>
          {results.data?.length > 0 && results.data.map((retailer: Retailer, index: number) => {
            return <Fragment key={`retailer-${index}`}>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} value={retailer.name.charAt(0).toUpperCase() + retailer.name.slice(1)} brandRetailerId={retailer.id} fieldname={`name`} icon={<GearIcon width={`20px`} height={`20px`} stroke={`#b8bfcc`}/>} onClickCell={() => setEditingFeatures(retailer)}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} isWrappedContent={true} value={retailer.country} selectChoices={COUNTRIES} brandRetailerId={retailer.id} fieldname={`country`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} isWrappedContent={true} value={retailer.brandName} selectChoices={BRANDS} brandRetailerId={retailer.id} fieldname={`brandName`} title={`id: ${retailer.brandId} / pool_id: ${retailer.poolId}`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_CONTAINER} value={<div>{retailer.domains.map((domain: string, domainIndex: number) => <div className={`text-center whitespace-nowrap overflow-hidden text-ellipsis w-full`} key={`retailer-domains-${index}-domain-${domainIndex}`}><a target={'_blank'} title={domain} href={`${!domain.includes('http') ? 'https://' : ''}${domain}`} className={`underline`}>{domain}</a></div>)}</div>} selectChoices={undefined} customClass={undefined} brandRetailerId={retailer.id} handleChangeInput={undefined} fieldname={'domains'}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} value={retailer.revenues} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`revenues`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} value={retailer.caaClicks} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`caaClicks`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled && isCellActivated('detection', retailer.brandName)} type={CONTENT_TYPE_INPUT_SELECT} value={retailer.detection} selectChoices={CONFIGURATION_STATUS} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`detection`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled && isCellActivated('lastChance', retailer.brandName)} type={CONTENT_TYPE_INPUT_SELECT} value={retailer.lastChance} selectChoices={CONFIGURATION_STATUS} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`lastChance`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled && isCellActivated('deeplink', retailer.brandName)} type={CONTENT_TYPE_INPUT_SELECT} value={retailer.deeplink} selectChoices={CONFIGURATION_STATUS} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`deeplink`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_INPUT_SELECT} value={retailer.caaStatus} selectChoices={CAA_STATUS} dropdownCustomClass={'w-[250px]'} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`caaStatus`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_INPUT_TEXT} value={retailer.comment} brandRetailerId={retailer.id} onClick={() => setEditingComment({...retailer})} fieldname={`comment`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_INPUT_SELECT} value={retailer.priority} selectChoices={PRIORITIES} dropdownCustomClass={'w-[250px]'} brandRetailerId={retailer.id} handleChangeInput={handleChangeInput} fieldname={`priority`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} value={retailer.checkedAt !== null ? Moment(retailer.checkedAt.date).format('DD/MM/YYYY hh:mm:ss') : ''} brandRetailerId={retailer.id} fieldname={`lastCheck`}/>
              <ContentCell isEnabled={retailer.isExtensionEnabled} type={CONTENT_TYPE_TEXT} value={retailer.checkedBy !== null ? `${retailer.checkedBy.firstname} ${retailer.checkedBy.lastname}` : ''} customClass={`border-r-[1px]`} brandRetailerId={retailer.id} fieldname={`lastChecker`} onClickCell={handleLastCheckerClick} id={`brandRetailerId${retailer.id}`}/>
            </Fragment>
          })}
        </div>
        {!results.hasMoreResults ? <div className={`bg-slate-100 p-[10px] flex justify-center items-center border-l-[1px] border-r-[1px]`}>
          End of list
        </div> : null}
      </div>
    </div>
    <div className={`border-[1px] p-[10px] flex items-center`}>
      {results.isLoading ? '' : `${results.data.length} / ${results.counter} records`}  {results.isLoading ? <span>Loading...</span> : null}
    </div>
  </PageTemplate>;
};
