import {useState} from "react";
import {validateInput} from "../Services/FormValidator";
import {getLabel} from "../Services/LabelMapper";

interface FormHookFieldInterface {
  name: string;
  value?: string;
  constrains?: FormFieldConstrains;
}

interface FormInterface {
  isLoading: boolean;
  error: string;
  success: string;
  isSubmittable: boolean;
  fields: {
    [name: string]: {
      focus: boolean;
      value: string;
      constrains: FormFieldConstrains;
      errors: string[];
      success: string[];
    };
  };
}

interface FormValuesInterface {
  [name: string]: string;
}

interface FormFieldConstrains {
  [fieldname: string]: {
    [constrainName: string]: string;
  };
}

interface FormInputConstrainsList {
  [inputName: string]: FormFieldConstrains;
}

interface FormHookInterface {
  form: FormInterface;
  setForm: (newState: any) => void;
  handleChange: (e: any) => void;
  handleFocus: (e: any) => void;
  handleBlur: (e: any) => void;
  checkForm: () => boolean;
  setConstrains: (constrains: FormInputConstrainsList, validateInputs?: boolean) => void;
  releaseFormLoading: (newState: {error?: string|undefined, success?: string|undefined}) => void,
  handleChangeCheckbox: (e: any) => void;
}

export const FormHook = (fields: FormHookFieldInterface[]): FormHookInterface => {
  const defaultState: FormInterface = {
    isLoading: false,
    error: '',
    success: '',
    fields: {},
    isSubmittable: false
  };

  fields.forEach((field: FormHookFieldInterface) => {
    defaultState.fields[field.name] = {
      focus: false,
      value: field.value ?? '',
      constrains: field.constrains ?? {},
      errors: [],
      success: [],
    }
  });

  const [form, setForm] = useState<FormInterface>(defaultState);

  const handleChange = (e: any) => {
    const {errors, success}: any = validateInput(e.target.value, form.fields[e.target.name].constrains);

    let newFormState = {...form};
    newFormState.fields[e.target.name].value = e.target.value;
    newFormState.fields[e.target.name].errors = errors;
    newFormState.fields[e.target.name].success = success;

    setForm(newFormState);
  };

  const handleChangeCheckbox = (e: any) => {
    const {errors, success}: any = validateInput(e.target.value, form.fields[e.target.name].constrains);

    let newFormState = {...form};
    newFormState.fields[e.target.name].value = e.target.checked.toString();
    newFormState.fields[e.target.name].errors = errors;
    newFormState.fields[e.target.name].success = success;

    setForm(newFormState);
  }

  const handleFocus = (e: any) => {
    let newFormState = {...form};
    newFormState.fields[e.target.name].focus = true;

    setForm(newFormState);
  }

  const handleBlur = (e: any) => {
    let newFormState = {...form};
    newFormState.fields[e.target.name].focus = false;

    setForm(newFormState);
  }

  const checkForm = () => {
    if (form.isLoading) {
      setForm(() => {
        return {
          ...form,
          success: '',
          error: '',
          isSubmittable: false,
          isLoading: false
        };
      });
      return false;
    }

    let hasFormError = false;
    let newFormState = {...form};

    Object.keys(form.fields).map((fieldName: string) => {
      const {errors, success} = validateInput(form.fields[fieldName].value, form.fields[fieldName].constrains);
      newFormState.fields[fieldName].errors = errors;
      newFormState.fields[fieldName].success = success;
      if (errors.length > 0) {
        hasFormError = true;
      }
    });

    if (hasFormError) {
      setForm(() => {
        return {
          ...newFormState,
          success: '',
          error: getLabel('check_form_inputs'),
          isSubmittable: false,
          isLoading: false
        };
      });
      return false;
    }

    setForm(() => {
      return {
        ...newFormState,
        isLoading: true,
        error: '',
        success: '',
        isSubmittable: true,
      }
    });

    return true;
  };

  const setConstrains = (constrains: FormInputConstrainsList, validateInputs: boolean|null = false) => {
    const newState: FormInterface = {...form};

    Object.keys(constrains).forEach((fieldName: string) => {
      if (form.fields[fieldName] !== undefined) {
        const fieldConstrains: FormFieldConstrains = constrains[fieldName];

        if (validateInputs) {
          const {errors, success} = validateInput(form.fields[fieldName].value, fieldConstrains);
          newState.fields[fieldName].errors = errors;
          newState.fields[fieldName].success = success;
        }

        newState.fields[fieldName].constrains = fieldConstrains;
      }
    });

    setForm(newState);
  };

  const releaseFormLoading = ({error, success}: any) => {
    setForm(() => {
      return {
        ...form,
        isLoading: false,
        error: error ?? '',
        success: success ?? ''
      }
    });
  };

  return {form, setForm, handleChange, handleFocus, handleBlur, checkForm, setConstrains, releaseFormLoading, handleChangeCheckbox};
};
