import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {FormHook} from "../Hooks/FormHook";
import {useEffect} from "react";
import {API_URL, ApiManager} from "../Services/ApiManager";
import {getLabel} from "../Services/LabelMapper";

const ACCOUNT_CREATE_FORM_VALIDATOR_URL = API_URL+'/form-validators/account-create';
const CREATE_ACCOUNT_URL = API_URL+'/accounts';

export const AccountCreatePage = () => {
  const {form, checkForm, handleFocus, handleChange, handleBlur, setConstrains, releaseFormLoading, setForm} = FormHook([{name: 'firstname'}, {name: 'lastname'}, {name: 'email'}, {name: 'password'}])

  useEffect(() => {
    (async function() {
      try {
        const response =  await ApiManager.get(ACCOUNT_CREATE_FORM_VALIDATOR_URL);
        setConstrains(response.data);
      } catch(error: any) {
        console.error(`Error while fetching form constrains:`, error);
      }
    })();
  }, []);

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }

    try {
      await ApiManager.post(CREATE_ACCOUNT_URL,{
        email: form.fields.email.value,
        firstname: form.fields.firstname.value,
        lastname: form.fields.lastname.value
      });

      releaseFormLoading({success: getLabel('account_create_success')});
    } catch(error: any) {
      console.error(`Error while fetching submitting form:`, error);
      setForm({...form, error: getLabel(error)})
    }
  };

  return <PageTemplate selectedMenu={'accounts'}>
    <div className={`flex justify-center items-center h-full`}>
      <div className={`w-[300px]`}>
        <div className={`mb-[30px]`}>
          <h1 className={`text-[20px]`}>New account</h1>
        </div>
        <form>
          <div>
            <input onFocus={handleFocus} onBlur={handleBlur} name={'email'} type={'text'} onChange={handleChange} value={form.fields.email.value} className={`border-[1px] ${form.fields.email.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Email'}/>
            {form.fields.email.focus ? <div className={`absolute bg-yellow-50 w-300[px]`}>
              {form.fields.email.errors.map((error: string, index: number) => <div className={`text-red-600`} key={`error-email-${index}`}>
                {error}
              </div>)}
              {form.fields.email.success.map((success: string, index: number) => <div className={`text-green-600`} key={`error-email-${index}`}>
                {success}
              </div>)}
            </div> : null}
          </div>
          <div className={`mt-[10px]`}>
            <input onFocus={handleFocus} onBlur={handleBlur} name={'firstname'} type={'firstname'} onChange={handleChange} value={form.fields.firstname.value} className={`border-[1px] ${form.fields.firstname.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Firstname'}/>
            {form.fields.firstname.focus ? <div className={`absolute bg-yellow-50 w-300[px]`}>
              {form.fields.firstname.errors.map((error: string, index: number) => <div className={`text-red-600`} key={`error-firstname-${index}`}>
                {error}
              </div>)}
              {form.fields.firstname.success.map((success: string, index: number) => <div className={`text-green-600`} key={`error-firstname-${index}`}>
                {success}
              </div>)}
            </div> : null}
          </div>
          <div className={`mt-[10px] mb-[30px]`}>
            <input onFocus={handleFocus} onBlur={handleBlur} name={'lastname'} type={'lastname'} onChange={handleChange} value={form.fields.lastname.value} className={`border-[1px] ${form.fields.lastname.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Lastname'}/>
            {form.fields.lastname.focus ? <div className={`absolute bg-yellow-50 w-300[px]`}>
              {form.fields.lastname.errors.map((error: string, index: number) => <div className={`text-red-600`} key={`error-lastname-${index}`}>
                {error}
              </div>)}
              {form.fields.lastname.success.map((success: string, index: number) => <div className={`text-green-600`} key={`error-lastname-${index}`}>
                {success}
              </div>)}
            </div> : null}
          </div>
          {form.error !== '' ? <div className={`text-red-600`}>{form.error}</div> : null}
          {form.success !== '' ? <div className={`text-green-600`}>{form.success}</div> : null}
          <div>
            <button className={`text-center w-[100%] border-black border-[1px] rounded p-[10px]`} onClick={submitForm} type={'submit'}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </PageTemplate>;
};
