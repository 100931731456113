import {LocalStorage} from "./LocalStorage";

const USER_CACHE_INDEX = 'user';

export interface USER {
  token: string;
  refreshTokenPassword: string;
  user: {
    email: string,
    firstname: string;
    lastname: string;
  }
}

export class UserCache {
  static save(user?: USER) {
    LocalStorage.save(USER_CACHE_INDEX, user);
  }

  static get(): USER | null {
    return LocalStorage.get(USER_CACHE_INDEX) as USER | null;
  }

  static remove(): void {
    LocalStorage.remove(USER_CACHE_INDEX);
  }
}
