import {getLabel} from "./LabelMapper";

export interface FormValidationResponseInterface {
  errors: string[];
  success: string[];
}

const validateInput = (value: string, criterion: any): FormValidationResponseInterface => {
  let success = [];
  let errors = [];

  for (const criterionName of Object.keys(criterion)) {
    let isSuccess = false;
    switch (criterionName) {
      case 'type':
        switch(criterion[criterionName]) {
          case 'alphanumeric':
            isSuccess = true;
            break;
          case 'email':
            isSuccess = /\S+@\S+\.\S+/.test(value);
            break;
        }
        break;
      case 'minLength':
        isSuccess = (criterion['blank'] !== undefined && criterion['blank'] === true && value.length === 0) || value.length >= criterion[criterionName];
        break;
      case 'maxLength':
        isSuccess = value.length <= criterion[criterionName];
        break;
      case 'required':
        isSuccess = criterion[criterionName] === true && value !== undefined;
        break;
      case 'blank':
        isSuccess = criterion[criterionName] === true || (criterion[criterionName] === false && value !== undefined && value.length > 0);
        break;
      case 'atLeastOneSpecialChar':
        isSuccess = criterion[criterionName] === true && atLeastOneSpecialChar(value);
        break;
      case 'atLeastOneLowerCase':
        isSuccess = criterion[criterionName] === true && atLeastOneLowerCase(value);
        break;
      case 'atLeastOneUpperCase':
        isSuccess = criterion[criterionName] === true && atLeastOneUpperCase(value);
        break;
      case 'atLeastOneNumber':
        isSuccess = criterion[criterionName] === true && atLeastOneNumber(value);
        break;
    }

    if (isSuccess) {
      success.push(translateCriterion(criterionName, criterion[criterionName]));
    } else {
      errors.push(translateCriterion(criterionName, criterion[criterionName]));
    }
  }

  return {success, errors};
};

const translateCriterion = (name: string, value: string): string => {
  let label = getLabel(`form_criterion_${name}`);
  switch(name) {
    case 'type':
      label = getLabel(`form_criterion_${name}_${value}`);
      break;
    case 'minLength':
    case 'maxLength':
      label = label.replace('{value}', value);
      break;
  }

  return label;
};

const atLeastOneSpecialChar = (value: string): boolean => {
  const specialChars = ',;:!?./§/&~"#\'{([-|`^@à)]=}*_';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneLowerCase = (value: string): boolean => {
  const specialChars = 'abcdefghijklmnopqrstuvwxyz';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneUpperCase = (value: string): boolean => {
  const specialChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneNumber = (value: string): boolean => {
  const specialChars = '0123456789';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

export {validateInput};
