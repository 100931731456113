import {useState, useRef, useEffect} from "react";

export const useComponentVisible = (initialIsVisible:any) => {
  const [isVisible, setIsVisible] = useState(
    initialIsVisible
  );
  const ref = useRef<HTMLInputElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };

  const handleClickOutside = (event:MouseEvent) => {
    if (ref.current !== null && !ref.current?.contains(event?.target as Node)) {
      setIsVisible(false);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(() => !isVisible)
  }

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isVisible, toggleVisibility };
}
