import {ReactComponent as ArrowDown} from "../../Assets/Icons/arrow_down.svg";
import {ReactComponent as ArrowUp} from "../../Assets/Icons/arrow_up.svg";
import {FiltersOrderBy} from "./RetailersPage";
import {ORDER_DIRECTION_ASC, ORDER_DIRECTION_DESC} from "./Constants";

interface HeaderCellInterface {
  title: string;
  fieldname?: string|undefined;
  orderingList: FiltersOrderBy[]|any;
  customClass?: string|undefined;
  handleOrderBy?: undefined|((fieldname: string) => void);
}
export const HeaderCell = ({title, fieldname, orderingList = [], customClass = undefined, handleOrderBy = undefined}: HeaderCellInterface) => {
  let orderIndex: number = 0;
  for (let i=0; i < orderingList.length; i++) {
    if (orderingList[i].field === fieldname) {
      orderIndex = i+1;
    }
  }

  return <div className={`bg-slate-100 min-w-[130px] flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] ${customClass}`}>
    {title} {undefined !== handleOrderBy && fieldname !== undefined ? <span onClick={() => handleOrderBy(fieldname)} className={`cursor-pointer ml-[5px]`}>
    {orderingList.find((order: FiltersOrderBy) => order.field === fieldname && order.direction === ORDER_DIRECTION_ASC) !== undefined ? <span className={`block p-[5px] pl-[0px] rounded bg-black flex items-center`} title={`Ascendant`}><ArrowDown width={`30px`} fill={`#fff`}/><span className={`rounded-full bg-white w-[20px] h-[20px] flex items-center justify-center`}>{orderIndex}</span></span> : null}
    {orderingList.find((order: FiltersOrderBy) => order.field === fieldname && order.direction === ORDER_DIRECTION_DESC) !== undefined ?  <span className={`block p-[5px] pl-[0px] rounded bg-black flex items-center`} title={`Descendant`}><ArrowUp width={`30px`} fill={`#fff`}/><span className={`rounded-full bg-white w-[20px] h-[20px] flex items-center justify-center`}>{orderIndex}</span></span> : null}
    {orderingList.find((order: FiltersOrderBy) => order.field === fieldname) === undefined ? <ArrowDown width={`30px`}/> : null}
    </span> : null}
  </div>;
};
