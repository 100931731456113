import {FilterInterface} from "./RetailersPage";

export type ORDER_DIRECTION = 'ASC' | 'DESC';
export const ORDER_DIRECTION_ASC = 'ASC';
export const ORDER_DIRECTION_DESC = 'DESC';

export const API_BASE_URL_DEV = "https://gsg-extension-fr-dev.igraal.com/";
export const API_BASE_URL_STAGING = "https://gsg-extension-fr-staging.igraal.com/";
export const API_BASE_URL_PROD = "https://gsg-extension-fr.igraal.com/";

export const BACKGROUND_RED = 'bg-red-100';
export const TEXT_RED = 'text-red-500';
export const BACKGROUND_GREEN = 'bg-green-100';
export const TEXT_GREEN = 'text-green-500';
export const BACKGROUND_GREY = 'bg-slate-100';
export const TEXT_GREY = 'text-slate-500';
export const BACKGROUND_BLUE = 'bg-sky-100';
export const TEXT_BLUE = 'text-sky-500';
export const BACKGROUND_ORANGE = 'bg-amber-100';
export const TEXT_ORANGE = 'text-amber-500';
export const BACKGROUND_PURPLE = 'bg-purple-100';
export const TEXT_PURPLE = 'text-purple-500';
export const BACKGROUND_YELLOW = 'bg-yellow-100';
export const TEXT_YELLOW = 'text-yellow-500';

export const PRIORITIES: FilterInterface[] = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'high',
    label: 'High',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  }
];

export const BRANDS: FilterInterface[] = [
  {
    value: 'igraal',
    label: 'iGraal',
    customClass: `${BACKGROUND_ORANGE} ${TEXT_ORANGE}`
  },
  {
    value: 'pouch',
    label: 'Pouch',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'shoop',
    label: 'Shoop',
    customClass: `${BACKGROUND_GREEN} ${TEXT_GREEN}`
  },
  {
    value: 'cuponation',
    label: 'Cuponation',
    customClass: `${BACKGROUND_YELLOW} ${TEXT_YELLOW}`
  }
];

export const COUNTRIES: FilterInterface[] = [
  {
    value: 'fr',
    label: 'FR',
    customClass: `${BACKGROUND_GREEN} ${TEXT_GREEN}`
  },
  {
    value: 'de',
    label: 'DE',
    customClass: `${BACKGROUND_ORANGE} ${TEXT_ORANGE}`
  },
  {
    value: 'es',
    label: 'ES',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'uk',
    label: 'UK',
    customClass: `${BACKGROUND_BLUE} ${TEXT_BLUE}`
  },
  {
    value: 'br',
    label: 'BR',
    customClass: `${BACKGROUND_YELLOW} ${TEXT_YELLOW}`
  }
];

export const CONFIGURATION_STATUS: FilterInterface[] = [
  {
    value: 'failure',
    label: 'Failure',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'ok',
    label: 'OK',
    customClass: `${BACKGROUND_GREEN} ${TEXT_GREEN}`
  },
  {
    value: 'off',
    label: 'OFF',
    customClass: `${BACKGROUND_GREY} ${TEXT_GREY}`
  },
  {
    value: 'to_configure',
    label: 'To configure',
    customClass: `${BACKGROUND_BLUE} ${TEXT_BLUE}`
  },
  {
    value: 'to_test',
    label: 'To test',
    customClass: `${BACKGROUND_ORANGE} ${TEXT_ORANGE}`
  },
];

export const CAA_STATUS: FilterInterface[] = [
  {
    value: 'failure_does_not_work',
    label: 'Failure - does not work',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'failure_hidden_selector_in_dom',
    label: 'Failure - hidden selector in Dom',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'failure_missing_selector',
    label: 'Failure - missing selector',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'failure_on_hold',
    label: 'Failure - on hold',
    customClass: `${BACKGROUND_RED} ${TEXT_RED}`
  },
  {
    value: 'cay',
    label: 'CAY',
    customClass: `${BACKGROUND_PURPLE} ${TEXT_PURPLE}`
  },
  {
    value: 'ok',
    label: 'OK',
    customClass: `${BACKGROUND_GREEN} ${TEXT_GREEN}`
  },
  {
    value: 'off',
    label: 'OFF',
    customClass: `${BACKGROUND_GREY} ${TEXT_GREY}`
  },
  {
    value: 'to_configure',
    label: 'To configure',
    customClass: `${BACKGROUND_BLUE} ${TEXT_BLUE}`
  },
  {
    value: 'to_test',
    label: 'To test',
    customClass: `${BACKGROUND_ORANGE} ${TEXT_ORANGE}`
  },
]
