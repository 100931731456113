import {Link} from "react-router-dom";

export const Menu = ({selectedMenu}: any) => {
  return <div className={`h-[100%] border-r-[1px] text-[20px]`}>
    <Link to={'/'}>
      <div className={`py-[20px] pl-[20px] hover:bg-slate-100 ${selectedMenu === 'home' ? 'text-sky-600' : ''}`}>Home</div>
    </Link>
    <Link to={'/accounts'}>
      <div className={`py-[20px] pl-[20px] hover:bg-slate-100 ${selectedMenu === 'accounts' ? 'text-sky-600' : ''}`}>Accounts</div>
    </Link>
    <Link to={'/retailers'}>
      <div className={`py-[20px] pl-[20px] hover:bg-slate-100 ${selectedMenu === 'retailers' ? 'text-sky-600' : ''}`}>Retailer Maintenance</div>
    </Link>
  </div>;
};
