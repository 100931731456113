import {REMOVE_USER_ACTION, UPDATE_USER_ACTION, UserContextInterface} from "../Contexts/UserContext";
import {UserCache} from "../Cache/UserCache";

export const API_URL = process.env.REACT_APP_API_BASE_URL;

export const REQUEST_METHOD_GET = 'GET';
export const REQUEST_METHOD_POST = 'POST';
export const REQUEST_METHOD_PUT = 'PUT';
type REQUEST_METHODS = 'GET' | 'POST' | 'DELETE' | 'PUT';

interface RequestOptions {
  method: REQUEST_METHODS,
  headers: {
    authorization?: string;
    'Content-Type'?: string;
  },
  body?: string;
}

export class ApiManager {
  static userContext: undefined|UserContextInterface = undefined;

  static async get(url: string) {
    return ApiManager.fireRequest(REQUEST_METHOD_GET, url);
  }

  static async post(url: string, payload: any) {
    return ApiManager.fireRequest(REQUEST_METHOD_POST, url, payload);
  }

  static async put(url: string, payload: any) {
    return ApiManager.fireRequest(REQUEST_METHOD_PUT, url, payload);
  }

  static async file(url: string, payload: any) {
    return ApiManager.fireRequest(REQUEST_METHOD_POST, url, payload, 'file');
  }

  static async fireRequest(method: REQUEST_METHODS, url: string, payload: any = undefined, bodyFormat: 'json'|'file' = 'json') {
    const requestOptions: RequestOptions = {
      method,
      headers: {}
    };

    let userCache = UserCache.get();
    if (userCache?.token) {
      requestOptions.headers.authorization = userCache.token;
    }

    if (payload !== undefined) {
      if ('json' === bodyFormat) {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(payload);
      } else {
        requestOptions.body = payload;
      }
    }

    let response = await fetch(url, requestOptions);
    if (!response.ok && response.status === 401 && requestOptions.headers.authorization !== undefined) {
      const refreshTokenResponse = await ApiManager.refreshToken();

      if (refreshTokenResponse.ok &&  ApiManager.userContext?.dispatchUser !== undefined) {
        ApiManager.userContext?.dispatchUser(UPDATE_USER_ACTION, refreshTokenResponse.data);

        userCache = UserCache.get();
        if (userCache?.token) {
          requestOptions.headers.authorization = userCache.token;
        }
        response = await fetch(url, requestOptions);
      } else {
        ApiManager.userContext?.dispatchUser(REMOVE_USER_ACTION);
        throw refreshTokenResponse;
      }
    }

    if (!response.ok) {
      throw await response.json();
    }

    return {data: await response.json(), status: response.status, ok: response.ok};
  }

  static async refreshToken() {
    const userCache = UserCache.get();
    const requestOptions: any = {
      method: 'POST',
      headers: {
        Authorization: userCache?.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({refreshTokenPassword: userCache?.refreshTokenPassword})
    };

    let response = await fetch(API_URL+'/refresh-token', requestOptions);

    return {data: await response.json(), status: response.status, ok: response.ok};
  }

  static async isStatusOk(url: string) {
    try {
      const response = await fetch(`${url}time`);
      return response.status === 200;
    }
    catch(e) {
      return false;
    }
  }
}
