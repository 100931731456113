import {useContext, useEffect, useState} from "react";
import {REMOVE_USER_ACTION, UserContext, UserContextInterface} from "../../Contexts/UserContext";
import {Link} from "react-router-dom";
import {ReactComponent as SyncphoniaLogo} from "../../Assets/Icons/syncPhonia_logo_transparent.svg";
import {ReactComponent as WarningTriangle} from "../../Assets/Icons/warning_triangle.svg";
import {ReactComponent as CheckCircle} from "../../Assets/Icons/check_circle.svg";
import {ApiManager} from "../../Services/ApiManager";
import {API_BASE_URL_DEV, API_BASE_URL_PROD, API_BASE_URL_STAGING} from "../../Pages/RetailersPage/Constants";
import { useComponentVisible } from "../../Hooks/ComponentVisibleHook";

export const Header = () => {
  let {
    ref,
    isVisible,
    toggleVisibility
  } = useComponentVisible(false);

  async function environmentChecker() {
    try {
      const isProdUp = await ApiManager.isStatusOk(API_BASE_URL_PROD);
      const isStagingUp = await ApiManager.isStatusOk(API_BASE_URL_STAGING);
      setServersDropdown({...serversDropdown, isProdUp, isStagingUp});
    } catch(error: any) {
      console.error(`Error while fetching logs:`, error);
    }
  }

  const userContext: UserContextInterface = useContext<UserContextInterface>(UserContext);
  const [serversDropdown, setServersDropdown] = useState({
    isOpened: false,
    isProdUp: false,
    isStagingUp: false,
    isDevUp: false,
  });

  useEffect(() => {
    (async function() {await environmentChecker()})();
    setInterval(environmentChecker, 60000);
  }, []);

  return <div className={`h-[70px] inline-flex p-[2px] border-b-[1px] w-[100%]`}>
    <Link to={'/'}><SyncphoniaLogo width={`200px`} height={`65px`} className={``}/></Link>
    <div className={`w-[25px] self-center ml-auto`} onClick={() => setServersDropdown({...serversDropdown, isOpened: !serversDropdown.isOpened})}>
      <div onClick={() => setServersDropdown({...serversDropdown, isOpened: !serversDropdown.isOpened})}>
        {(serversDropdown.isProdUp && serversDropdown.isStagingUp) ?
          <CheckCircle className={`fill-[green] cursor-pointer`}/> :
          <WarningTriangle className={`fill-[yellow] cursor-pointer text-red-500`}/>
        }
      </div>
      {serversDropdown.isOpened ?
        <div className={`absolute right-[220px] top-[50px] bg-white w-[200px] border-[1px] z-40 rounded-xl`}>
          <a href={`${API_BASE_URL_PROD}time`} target='_blank'>
            <div className={`p-[10px] flex hover:bg-slate-100 cursor-pointer`}>
              <div className={`w-[25px]`}>{serversDropdown.isProdUp ?
                <CheckCircle className={`fill-[green]`}/>
                : <WarningTriangle className={`fill-[yellow]`}/>}
              </div>&nbsp;production
            </div>
          </a>
          <hr/>
          <a href={`${API_BASE_URL_STAGING}time`} target='_blank'>
            <div className={`p-[10px] flex hover:bg-slate-100 cursor-pointer`}>
              <div className={`w-[25px]`}>{serversDropdown.isStagingUp ?
                <CheckCircle className={`fill-[green]`}/>
                : <WarningTriangle className={`fill-[yellow]`}/>}
              </div>&nbsp;staging
            </div>
          </a>
        </div>
        : null}
    </div>
    {userContext.user !== undefined && userContext.user.user !== undefined ?
      <div className={`p-[8px] inline-flex`} ref={ref}>
        <div className={`border-[1px] rounded-2xl p-[10px] w-[200px] text-center cursor-pointer`} onClick={() => toggleVisibility()}>
          {userContext.user.user.firstname+' '+userContext.user.user.lastname}
        </div>
        {isVisible ? <div className={`absolute right-[10px] pt-[20px] top-[40px] bg-white w-[200px] border-l-[1px] border-r-[1px] border-b-[1px] rounded-b-2xl z-40`}>
          <Link to={`/logs`}><div className={`border-t-[1px] p-[10px] hover:bg-slate-100 cursor-pointer`} onClick={() => toggleVisibility()}>
            Logs
          </div></Link>
          <Link to={`/tools`}><div className={`p-[10px] hover:bg-slate-100 cursor-pointer`} onClick={() => toggleVisibility()} >
            Tools
          </div></Link>
          <Link to={`/settings`}><div className={`p-[10px] hover:bg-slate-100 cursor-pointer`} onClick={() => toggleVisibility()} >
            Settings
          </div></Link>
          <hr/>
          <div className={`p-[10px] hover:bg-slate-100 cursor-pointer`} onClick={() => userContext.dispatchUser(REMOVE_USER_ACTION)}>
            Log out
          </div>
        </div>: null}
      </div>
    : null}
  </div>
};
