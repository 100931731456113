import {Retailer} from './RetailersPage';

interface CommentModalInterface {
  retailer: Retailer;
  closeModal: () => void;
  handleChangeInput: (id: string, fieldname: string, value: string) => void;
  onChangeValue: (e: any) => void;
}

export const CommentModal = ({retailer, closeModal, handleChangeInput, onChangeValue}: CommentModalInterface) => {
  return <div className={`fixed w-full z-50 top-0 left-0`}>
    <div className={`fixed w-full top-0 left-0 flex h-[100vh] bg-slate-100 opacity-50`} onClick={closeModal}/>
    <div className={`fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[50%] border-[1px] bg-white p-[30px] rounded`}>
      <div className={`flex justify-between items-center`}>
        <div className={`text-[20px]`}>
          Comment <span className={`font-bold`}>[ {retailer.name} - {retailer.brandName} - {retailer.country} ]</span>
        </div>
        <div className={`text-right`}>
          <button className={`text-[20px] border-[1px] py-[5px] px-[10px] rounded hover:bg-slate-100`} onClick={() => {handleChangeInput(retailer.id, 'comment', retailer.comment); closeModal();}}>Save</button>
        </div>
      </div>
      <div className={`pt-[20px]`}>
        <textarea autoFocus={true} onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)} className={`w-full border-[1px] rounded h-[300px] p-[10px]`} value={retailer.comment} onChange={onChangeValue}/>
      </div>
    </div>
  </div>;
};
