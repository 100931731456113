import {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {API_URL, ApiManager} from "../Services/ApiManager";
import {getLabel} from "../Services/LabelMapper";

export interface Log {
  executionDate: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  operationType: string;
  logType: string;
  inputData: any;
  outputData: any;
  status: string;
  account: {
    firstname?: string;
    lastname?: string;
  };
}

export interface LogList {
  [name: string]: Log;
}


const AUTOMATION_LOGS_URL = API_URL+'/logs';

export const LogsPage = () => {
  const [logs, setLogs] = useState<LogList>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function() {
      try {
        const response =  await ApiManager.get(AUTOMATION_LOGS_URL);
        setLogs(() => response.data);
        setIsLoading(() => false);
      } catch(error: any) {
        console.error(`Error while fetching logs:`, error);
        setIsLoading(() => false);
      }
    })();
  }, []);

  const getUserName = (log: Log) => {
    if (undefined !== log.account.firstname && undefined !== log.account.lastname) {
      return log.account.firstname + ' ' + log.account.lastname;
    }

    if (undefined === log.account.firstname && undefined === log.account.lastname) {
      return 'No user';
    }

    if (undefined === log.account.firstname || undefined === log.account.lastname) {
      return undefined === log.account.firstname ? log.account.lastname : log.account.firstname;
    }
  };

  const getLogLabel = (log: Log): string => {
    switch (log.operationType) {
      case 'FETCH_BRAND_RETAILER':
        if (log.inputData.brand !== undefined) {
          switch (log.inputData.brand) {
            case 'igraal':
              return `Fetch merchants from ${log.inputData.brand} (${log.inputData.country})`;
            default:
              return `Fetch merchants from ${log.inputData.brand}`;
          }
        }
        return `Fetch merchants from unknown brand resulted in status ${log.status}`;
      case 'SYNCHRONIZE_BRAND_REVENUES':
        if (log.inputData.brand !== undefined) {
          switch (log.inputData.brand) {
            case 'igraal':
              return `Sync revenues for ${log.inputData.brand} (${log.inputData.country})`;
            default:
              return `Sync revenues for ${log.inputData.brand}`;
          }
        }
        return `Fetch revenues from unknown brand resulted in status ${log.status}`;
      case 'SYNCHRONIZE_BRAND_CAA_CLICKS':
        if (log.inputData.brand !== undefined) {
            return `Sync CAA impression for ${log.inputData.brand}`;
        }
        return `Fetch CAA impressions from unknown brand resulted in status ${log.status}`;
      case 'OPERATION_RESET_PASSWORD_REQUEST':
        return `Reset password request for ${log?.inputData?.email}`;
      case 'OPERATION_LOGIN':
        return `Log in ${log?.inputData?.email}`;
      case 'OPERATION_PASSWORD_SET':
        return `Set up password for ${log?.inputData?.email}`;
      case 'OPERATION_ACCOUNT_CREATE':
        return `Account creation with ${log?.inputData?.email} ${log?.inputData?.firstname} ${log?.inputData?.lastname}`;
      case 'OPERATION_COMPUTE_RETAILERS':
        return `Combine all brands retailers`;
      case 'OPERATION_UPDATE_BRAND_RETAILER_MAINTENANCE':
        return `Updated brand retailer`;
      case 'OPERATION_UPDATE_BRAND_RETAILER_FEATURES':
        return `Features updated for ${log.inputData?.retailer?.name ?? 'Unknown retailer'}`;
      case 'OPERATION_GENERATE_RETAILERS_JSON_FILE':
        return `Extension sync ${log.inputData?.brand} (${log.inputData?.country})`;
      default:
        return `Launched unknown automation: ${log.operationType}`;
    }
  };

  return <PageTemplate>
    <div className={`text-[30px] mb-[30px]`}>
      Logs
    </div>
    <div>
      {isLoading ? <div>Loading...</div>: null}
      {!isLoading ? <div className={`h-[calc(100vh-240px)] overflow-auto border-t-[1px]`}>
        <div className={`grid grid-cols-7 sticky z-20 top-0 bg-white`}>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>Date/time</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>Type</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>User</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>Status</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>Description</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100`}>Inputs</div>
          <div className={`flex items-center justify-center p-[10px] h-[70px] border-b-[1px] border-l-[1px] bg-slate-100 border-r-[1px]`}>Outputs</div>
        </div>
        <div className={`grid grid-cols-7 bg-white`}>
          {logs !== undefined && Object.keys(logs).map((index: string) => {
            const log: Log = logs[index];
            const logDate = moment(log.executionDate.date).format('YYYY-MM-DD hh:mm:ss');
            const userName = getUserName(log);
            const logCLass = log.status === 'FAIL' ? `bg-red-100` : '';

            return <Fragment key={`log-${index}`}>
              <div className={`flex items-center justify-center p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{logDate}</div>
              <div className={`flex items-center justify-center p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{log.logType}</div>
              <div className={`flex items-center justify-center p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{userName}</div>
              <div className={`flex items-center justify-center p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{log.status}</div>
              <div className={`flex items-center justify-start p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{getLogLabel(log)}</div>
              <div className={`flex items-center justify-start p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}><div className={`overflow-hidden text-ellipsis`} title={JSON.stringify(log.inputData)}>{JSON.stringify(log.inputData)}</div></div>
              <div className={`flex items-center justify-start p-[10px] border-b-[1px] border-l-[1px] ${logCLass}`}>{log.outputData.message !== undefined ? getLabel(log.outputData.message) : log.outputData.length === 0 ? '' : JSON.stringify(log.outputData)}</div>
            </Fragment>;
          })}
        </div>
      </div>: null}
    </div>
    <div className={`border-[1px] p-[10px] flex items-center justify-start`}>
      {logs !== undefined ? `${Object.keys(logs).length} records` : 'Loading...'}
    </div>
  </PageTemplate>;
}
