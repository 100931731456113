import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {useContext} from "react";
import {UserContext, UserContextInterface} from "../Contexts/UserContext";

export const LoggedHomepage = () => {
  const userContext = useContext<UserContextInterface>(UserContext);

  return <PageTemplate selectedMenu={'home'}>
    <div className={`flex justify-center items-center h-full text-[20px]`}>
      <div className={`border-r-[1px] mr-[10px] pr-[10px]`}>
        Happy to<br/>
        see you
      </div>
      <div className={`text-[70px] font-bold`}>
        {userContext.user?.user?.firstname ?? null}
      </div>
    </div>
  </PageTemplate>;
};
