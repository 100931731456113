import {ReactComponentElement, ReactElement} from "react";
import {FilterInterface} from "./RetailersPage";
import { useComponentVisible } from "../../Hooks/ComponentVisibleHook";

type CONTENT_TYPE = 'text'|'inputText'|'inputSelect' | 'container';
export const CONTENT_TYPE_CONTAINER = 'container';
export const CONTENT_TYPE_TEXT = 'text';
export const CONTENT_TYPE_INPUT_TEXT = 'inputText';
export const CONTENT_TYPE_INPUT_SELECT = 'inputSelect';
interface ContentCellInterface {
  type: CONTENT_TYPE,
  value: string|ReactComponentElement<any>;
  selectChoices?: FilterInterface[]|undefined;
  customClass?: string|undefined;
  handleChangeInput?: (brandRetailerId: string, fieldname: string, value: string) => void;
  brandRetailerId: string;
  fieldname: string;
  dropdownCustomClass?: string;
  onClick?: () => void;
  isWrappedContent?: boolean;
  title?: string;
  icon?: ReactElement;
  onClickCell?: (e?: any) => void;
  isEnabled: boolean;
  id?: string;
}
export const ContentCell = ({type = CONTENT_TYPE_TEXT, isEnabled, value, selectChoices, customClass, handleChangeInput = () => {}, brandRetailerId, fieldname, dropdownCustomClass = 'w-[100px]', onClick = () => {}, isWrappedContent = false, title = undefined, icon = undefined, onClickCell = undefined, id = undefined}: ContentCellInterface) => {
  let {
    ref,
    isVisible,
    toggleVisibility
  } = useComponentVisible(false);

  const choice = selectChoices?.find((choice: FilterInterface) => choice.value === value);
  const choiceLabel = choice?.label ?? '';
  const wrappedContentClass = isWrappedContent ? `border-[1px] py-[5px] px-[10px] rounded ${choice?.customClass}` : '';

  let commonProps = {};
  if (title !== undefined) {
    commonProps = {...commonProps, title}
  }

  const disabledStateClass = isEnabled ? '' : 'bg-slate-300 text-slate-600 cursor-default';
  if (!isEnabled) {
    toggleVisibility = () => {};
    onClick = () => {};
  }

  return <div className={`border-b-[1px] border-l-[1px] ${onClickCell === undefined ? '' : 'cursor-pointer'} ${disabledStateClass}`} onClick={onClickCell}>
    {icon === undefined ? null : <div className={`w-0 h-0 float-right mr-[20px]`}>
      {icon}
    </div>}
    <div ref={ref} className={`${type === CONTENT_TYPE_INPUT_TEXT ? 'inline-block' : 'flex' } p-[10px] h-full items-center justify-center ${customClass}`}>
      {type === CONTENT_TYPE_TEXT ? <span className={`${wrappedContentClass} ${disabledStateClass}`} {...commonProps} data-brand-retailer-id={brandRetailerId} id={id}>{choice !== undefined ? choiceLabel : value}</span>: null}
      {type === CONTENT_TYPE_CONTAINER ? <div className={`${wrappedContentClass} w-full`}>{choice !== undefined ? choiceLabel : value}</div>: null}
      {type === CONTENT_TYPE_INPUT_TEXT ? <textarea className={`h-full w-full border-[1px] p-[5px] rounded resize bg-white relative ${disabledStateClass}`} value={value as  string} onClick={onClick} readOnly={true}/>: null}
      {type === CONTENT_TYPE_INPUT_SELECT && selectChoices !== undefined ? <div className={`cursor-pointer  w-full h-full flex justify-center items-center rounded ${choice !== undefined ? 'border-[1px]' : ''} ${choice?.customClass} ${disabledStateClass}`}>
        <div onClick={() => toggleVisibility()} className={`w-full h-full flex justify-center items-center p-[10px]`}>
          <span className={choiceLabel ? wrappedContentClass : ''}>{choiceLabel}</span>
        </div>
        {isVisible ? <div className={`ml-[-50px] w-[50px] h-[10px] mt-[-10px] z-10`}>
          <div className={`border rounded ${dropdownCustomClass} bg-white text-black`}>
            {selectChoices.map((choice: FilterInterface, choiceIndex: number) => <div key={`brand-retailer-field-${fieldname}-${brandRetailerId}-${choiceIndex}`} className={`p-[3px] cursor-pointer bg-white ${choice.customClass}`} onClick={() => {handleChangeInput(brandRetailerId, fieldname, choice.value); toggleVisibility();}}>
              {choice.label}
            </div>)}
          </div>
        </div>: null}
      </div>: null}
    </div>
  </div>;
}
