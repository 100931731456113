import {useContext} from 'react';
import {Retailer} from './RetailersPage';
import {BRANDS, COUNTRIES} from './Constants';
import {FilterInterface} from './RetailersPage';
import {ReactComponent as CloseIcon} from '../../Assets/Icons/close.svg';
import  {FormHook} from '../../Hooks/FormHook';
import {API_URL, ApiManager} from "../../Services/ApiManager";
import {UpdateRetailerDataInterface} from './RetailersPage';
import {NotificationContextInterface, NotificationContext, ADD_NOTIFICATION_ACTION, NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED, NOTIFICATION_STATUS_SUCCESS, NOTIFICATION_STATUS_FAIL} from '../../Contexts/ToasterNotificationContext';
import {ReactComponent as WarningIcon} from '../../Assets/Icons/warning_triangle.svg';
import {Link} from "react-router-dom";

interface FeaturesModalInterface {
  retailer: Retailer;
  closeModal: () => void;
  updateRetailerData: (retailerId: string, dataToUpdate: UpdateRetailerDataInterface) => void;
}

const UPDATE_FEATURES_URL = API_URL+'/retailers/{retailerId}/features';

export const FeaturesModal = ({retailer, closeModal, updateRetailerData}: FeaturesModalInterface) => {
  const notificationContext = useContext<NotificationContextInterface>(NotificationContext);

  const {handleChange, checkForm, form, handleChangeCheckbox} = FormHook([{name: 'isExtensionEnabled', value: retailer.isExtensionEnabled.toString()}]);

  const brand = BRANDS.find((choice: FilterInterface) => choice.value === retailer.brandName);
  const brandName = brand?.label ?? '';
  const brandClass = `border-[1px] py-[5px] px-[10px] rounded ${brand?.customClass}`;

  const country = COUNTRIES.find((choice: FilterInterface) => choice.value === retailer.country);
  const countryName = country?.label ?? '';
  const countryClass = `border-[1px] py-[5px] px-[10px] rounded ${country?.customClass}`;

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }

    try {
      const response = await ApiManager.put(UPDATE_FEATURES_URL.replace('{retailerId}', retailer.id), {
        isExtensionEnabled: form.fields.isExtensionEnabled.value,
      });

      updateRetailerData(retailer.id, {
        isExtensionEnabled: form.fields.isExtensionEnabled.value === 'true',
      });

      notificationContext.dispatchNotifications(
        ADD_NOTIFICATION_ACTION,
        {
          operation: NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED,
          status: NOTIFICATION_STATUS_SUCCESS,
          inputs: {merchantName: retailer.name.charAt(0).toUpperCase() + retailer.name.slice(1)}
        }
      );

      closeModal();
    } catch(e: any) {
      notificationContext.dispatchNotifications(
        ADD_NOTIFICATION_ACTION,
        {
          operation: NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED,
          status: NOTIFICATION_STATUS_FAIL,
          inputs: {merchantName: retailer.name.charAt(0).toUpperCase() + retailer.name.slice(1)}
        }
      );
      console.error('Error while updating features', e);
    }
  };

  return <div className={`fixed w-full z-50 top-0 left-0`}>
    <div className={`fixed w-full top-0 left-0 flex h-[100vh] bg-slate-100 opacity-50`} onClick={closeModal}/>
    <div className={`rounded fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-w-[700px] border-[1px] bg-white p-[30px]`}>
      <div>
        <div className={`text-center text-[30px]`}>
          {retailer.name.charAt(0).toUpperCase() + retailer.name.slice(1)}
        </div>
        <div className={`flex justify-center mb-[10px]`}>
          <div className={`${brandClass}`}>{brand !== undefined ? brandName : retailer.brandName}</div>
          <div className={`${countryClass} ml-[10px]`}>{country !== undefined ? countryName : retailer.country}</div>
        </div>
      </div>
      <form>
        <div>
          <div className={`flex p-[30px] border-y border-black`}>
            <div>
              <div className={`border border-black h-auto rounded p-[10px] w-[200px]`}>
                <ul className={`list-none`}>
                  {retailer.domains.map((domain: string, index: number) => <li className={`${index === 0 ? '' : 'mt-[10px]'} truncate ...`} key={`retailer-domain-${index}`}>
                      - <a target={'_blank'} href={`${!domain.includes('http') ? 'https://' : ''}${domain}`} className={`underline`} title={domain}>{domain}</a>
                  </li>)}
                </ul>
              </div>
            </div>
            <div className={`ml-[20px] p-[10px]`}>
              <div>
                <div>
                  <label htmlFor="extension_enabled" className="flex items-center cursor-pointer">
                    <div className="relative">
                      <input type="checkbox" id="extension_enabled" name={'isExtensionEnabled'} className="sr-only" onChange={handleChangeCheckbox} checked={form.fields.isExtensionEnabled.value === 'true' ? true : false}/>
                      <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                      <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                    </div>
                    <div className="ml-3 text-gray-700 font-medium">
                      Browser extension - overall
                    </div>
                  </label>
                </div>
              </div>
              <div className={`mt-[20px]`}>
                <WarningIcon className={`fill-[yellow] w-[25px] inline-block`}/> Once statuses are saved, it will take up to 24h to be applied on Extensions. Otherwise, you can apply them manually on the <Link to={`/tools`} className={`underline text-blue-500`}>Tools page</Link>.
              </div>
            </div>
          </div>
          <div className={`pt-[10px] flex justify-center`}>
            <button type={'submit'} className={`border py-[10px] px-[15px] rounded-xl cursor-pointer`} onClick={submitForm}>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>;
};
