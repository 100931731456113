import {UPDATE_USER_ACTION, UserContext, UserContextInterface} from "../Contexts/UserContext";
import {useContext, useEffect} from "react";
import {Header} from "../Components/Header/Header";
import {FormHook} from "../Hooks/FormHook";
import {getLabel} from "../Services/LabelMapper";
import {API_URL, ApiManager} from "../Services/ApiManager";

const LOGIN_FORM_VALIDATOR_URL = API_URL+'/form-validators/login';
const LOGIN_URL = API_URL+'/login';

export const UnloggedHomepage = () => {
  const userContext = useContext<UserContextInterface>(UserContext);
  const {form, handleChange, checkForm, setConstrains, releaseFormLoading, setForm} = FormHook([{name: 'email'}, {name: 'password'}]);

  useEffect(() => {
    (async function() {
      try {
        const response =  await ApiManager.get(LOGIN_FORM_VALIDATOR_URL);
        setConstrains(response.data);
      } catch(error) {
        console.error(`Error while fetching form constrains:`, error);
      }
    })();
  }, []);

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }

    try {
      const response =  await ApiManager.post(LOGIN_URL,{
        email: form.fields.email.value,
        password: form.fields.password.value
      });
      userContext.dispatchUser(UPDATE_USER_ACTION, response.data);
    } catch(error: any) {
      console.error(`Error while submitting form:`, error);
      setForm({...form, error: getLabel(error)})
    }
  };

  return <div>
    <Header/>
    <div className={`flex min-h-[calc(100vh-70px)] w-full items-center justify-center`}>
      <div className={`w-[300px]`}>
        <div className={`mb-[30px]`}>
          <h1 className={`text-[20px]`}>Login</h1>
        </div>
        <form>
          <div>
            <input name={'email'} type={'text'} onChange={handleChange} value={form.fields.email.value} className={`border-[1px] ${form.fields.email.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Email'}/>
          </div>
          <div className={`mt-[10px] mb-[30px]`}>
            <input name={'password'} type={'password'} onChange={handleChange} value={form.fields.password.value} className={`border-[1px] ${form.fields.password.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Password'}/>
          </div>
          {form.error !== '' ? <div className={`text-red-600`}>{form.error}</div> : null}
          <div>
            <button className={`text-center w-[100%] border-black border-[1px] rounded p-[10px]`} onClick={submitForm} type={'submit'}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
};
