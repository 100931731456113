import {Header} from "../Components/Header/Header";
import {FormHook} from "../Hooks/FormHook";
import {getLabel} from "../Services/LabelMapper";
import {useEffect} from "react";
import {API_URL, ApiManager} from "../Services/ApiManager";

const RESET_PASSWORD_FORM_VALIDATOR_URL = API_URL+'/form-validators/reset-password';
const RESET_PASSWORD_URL = API_URL+'/reset-password';

export const ResetPasswordPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const passwordFromUrl = urlParams.get('password');
  const emailFromUrl = urlParams.get('email') ?? '';

  const {form, handleChange, handleFocus, handleBlur, checkForm, setConstrains, releaseFormLoading, setForm} = FormHook([{name: 'email', value: emailFromUrl}, {name: 'password'}]);

  useEffect(() => {
    (async function() {
      try {
        const response =  await ApiManager.get(RESET_PASSWORD_FORM_VALIDATOR_URL);
        setConstrains(response.data);
      } catch(error) {
        console.error(`Error while fetching form constrains:`, error);
      }
    })();
  }, []);


  const submitForm = async (e: any) => {
    e.preventDefault();
    if (!checkForm()) {
      return;
    }

    try {
      await ApiManager.post(RESET_PASSWORD_URL,{
        email: form.fields.email.value,
        password: form.fields.password.value,
        oldPassword: passwordFromUrl === null ? '' : passwordFromUrl
      });
      releaseFormLoading({success: getLabel('user_password_set')});
    } catch(error: any) {
      console.error(`Error while fetching submitting form:`, error);
      setForm({...form, error: getLabel(error)})
    }
  }

  return <div>
    <Header/>
    <div className={`flex h-[calc(100vh-70px)] w-full items-center justify-center`}>
      <div className={`w-[300px]`}>
        <div className={`mb-[30px]`}>
          <h1 className={`text-[20px]`}>Reset password</h1>
        </div>
        <form>
          <div>
            <input onFocus={handleFocus} onBlur={handleBlur} name={'email'} type={'text'} value={form.fields.email.value} className={`border-[1px] ${form.fields.email.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Email'} onChange={handleChange}/>
            {form.fields.email.focus ? <div className={`absolute bg-yellow-50 w-300[px]`}>
              {form.fields.email.errors.map((error: string, index: number) => <div className={`text-red-600`} key={`error-email-${index}`}>
                {error}
              </div>)}
              {form.fields.email.success.map((success: string, index: number) => <div className={`text-green-600`} key={`error-email-${index}`}>
                {success}
              </div>)}
            </div> : null}
          </div>
          <div className={`mt-[10px] mb-[30px]`}>
            <input onFocus={handleFocus} onBlur={handleBlur} name={'password'} type={'password'} value={form.fields.password.value} className={`border-[1px] ${form.fields.password.errors.length > 0 ? 'border-red-600' : 'border-black'} rounded p-[10px] w-full`} placeholder={'Password'} onChange={handleChange}/>
            {form.fields.password.focus ? <div className={`absolute bg-yellow-50 w-300[px]`}>
              {form.fields.password.errors.map((error: string, index: number) => <div className={`text-red-600`} key={`error-password-${index}`}>
                {error}
              </div>)}
              {form.fields.password.success.map((success: string, index: number) => <div className={`text-green-600`} key={`error-password-${index}`}>
                {success}
              </div>)}
            </div> : null}
          </div>
          {form.error !== '' ? <div className={`text-red-600`}>{form.error}</div> : null}
          {form.success !== '' ? <div className={`text-green-600`}>{form.success}</div> : null}
          <div>
            <button className={`text-center w-[100%] border-black border-[1px] rounded p-[10px]`} onClick={submitForm} type={'submit'}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>;
};
