import {BrowserRouter, Routes, Route} from "react-router-dom";
import {LoggedHomepage} from "./Pages/LoggedHomepage";
import {UnloggedHomepage} from "./Pages/UnloggedHomepage";
import {ResetPasswordPage} from "./Pages/ResetPasswordPage";
import {LogsPage} from "./Pages/LogsPage";
import {AccountsPage} from "./Pages/AccountsPage";
import {AccountCreatePage} from "./Pages/AccountCreatePage";
import {RetailersPage} from "./Pages/RetailersPage/RetailersPage";
import {useContext} from "react";
import {UserContext, UserContextInterface} from "./Contexts/UserContext";
import {ApiManager} from "./Services/ApiManager";
import {ToolsPage} from "./Pages/ToolsPage";
import {ToasterNotifications} from './Components/ToasterNotifications/ToasterNotifications';
import {SettingsPage} from "./Pages/SettingsPage";

export const Router = () => {
  const userContext = useContext<UserContextInterface>(UserContext);
  ApiManager.userContext = userContext;

  return <>
    <ToasterNotifications/>
    {undefined !== userContext.user ?
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<LoggedHomepage/>}/>
          <Route path={'/logs'} element={<LogsPage/>}/>
          <Route path={'/tools'} element={<ToolsPage/>}/>
          <Route path={'/settings'} element={<SettingsPage/>}/>
          <Route path={'/reset-password'} element={<ResetPasswordPage/>}/>
          <Route path={'/accounts'} element={<AccountsPage/>}/>
          <Route path={'/accounts/create'} element={<AccountCreatePage/>}/>
          <Route path={'/retailers'} element={<RetailersPage/>}/>
          <Route path="*" element={<LoggedHomepage />} />
        </Routes>
      </BrowserRouter>
    :
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<UnloggedHomepage/>}/>
          <Route path={'/reset-password'} element={<ResetPasswordPage/>}/>
          <Route path="*" element={<UnloggedHomepage />} />
        </Routes>
      </BrowserRouter>
    }
  </>;
};
