import {useContext, useEffect} from 'react';
import {NotificationContextInterface, NotificationContext, NotificationBlockInterface, NOTIFICATION_STATUS_SUCCESS, NOTIFICATION_STATUS_FAIL, CLEAR_NOTIFICATIONS_ACTION, TOASTER_TIMER} from '../../Contexts/ToasterNotificationContext';
import {ReactComponent as CheckIcon} from '../../Assets/Icons/check_circle.svg';
import {ReactComponent as WarningIcon} from '../../Assets/Icons/warning_triangle.svg';

export const ToasterNotifications = () => {
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    if (notificationContext.notifications.length > 0) {
      const timer = setTimeout(() => notificationContext.dispatchNotifications(CLEAR_NOTIFICATIONS_ACTION));
      return () => clearTimeout(timer);
    }
  }, [notificationContext.notifications]);

  return <div className={`fixed top-0 left-[50%] translate-x-[-150px] w-[300px] p-[10px] z-[5000]`}>
    {notificationContext.notifications.map((notification: NotificationBlockInterface, index: number) => <div key={`notification-${index}`} className={`border bg-white rounded shadow mt-[20px]`}>
      <div className={`p-[10px] border-b flex items-center`}>
        {notification.status === NOTIFICATION_STATUS_SUCCESS && <CheckIcon width={`20px`} className={`fill-[green] mr-[10px]`}/>}
        {notification.status === NOTIFICATION_STATUS_FAIL && <WarningIcon width={`20px`} className={`fill-[yellow] mr-[10px]`}/>}
        {notification.title}
      </div>
      <div className={`p-[10px]`}>
        {notification.description}
      </div>
    </div>)}
  </div>
};
