import {useEffect, useState} from "react";
import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {API_URL, ApiManager} from "../Services/ApiManager";

const BRAND_NAMES = ['igraal', 'pouch', 'shoop', 'cuponation'];
const COLUMNS_NAMES = ['detection', 'lastChance', 'deeplink'];
const GET_CELLS_SETTINGS = API_URL+'/settings/get/deactivatedCells';
const UPDATE_SETTINGS = API_URL+'/settings/update/';

export const SettingsPage = () => {
  const [cellsSettings, setCellsSettings] = useState<{[name: string]: {[column: string]: boolean}}>({
    igraal: {
      detection: true,
      lastChance: true,
      deeplink: true
    },
    pouch: {
      detection: true,
      lastChance: true,
      deeplink: true
    },
    shoop: {
      detection: true,
      lastChance: true,
      deeplink: true
    },
    cuponation: {
      detection: true,
      lastChance: true,
      deeplink: true
    },
  });

  const fetchCellsSettings = async () => {
    try {
      const response = await ApiManager.get(GET_CELLS_SETTINGS);
      setCellsSettings(response.data?.value);
    } catch(e: any) {
      console.error(`Error while fetching settings`, e);
    }
  };

  const updateDeactivatedCell = (brandName: string, column: string) => {
    (async function () {
      try {
        cellsSettings[brandName][column] = !cellsSettings[brandName][column];
        setCellsSettings(cellsSettings);
        await ApiManager.put(`${UPDATE_SETTINGS}deactivatedCells`, {
          value: cellsSettings
        });

        await fetchCellsSettings();
      } catch (e: any) {
        console.error('Error while updating checkers', e);
      }
    })();
  };

  useEffect(() => {
    fetchCellsSettings();
  }, [])

  return <PageTemplate>
    <div className={`text-[30px] mb-[30px]`}>
      Settings
    </div>
    <div>
      <div>
        <div className={`text-[25px] flex items-center gap-[10px]`}>
          Retailers deactivated fields
        </div>
        <div className={`italic`}>Deactivate fields in the retailers array for some brands</div>
      </div>

        {BRAND_NAMES.map((brand) => {
          return (<div className={`grid grid-cols-2 gap-2 my-[30px] w-3/6`}>
          <div className={'row-span-3'}>{brand.toUpperCase()}</div>
            {COLUMNS_NAMES.map((column) => {
              return (
                <div className={`row-span-1 col-span-1`}>
                  <input type={'checkbox'} checked={cellsSettings[brand][column]} className={`border rounded px-[10px] py-[5px] hover:bg-slate-200 mx-2`} name={`${brand}_${column}`} onChange={() => updateDeactivatedCell(brand, column)} />
                  {column}
                </div>
              )
            })}
          </div>)
        })}
    </div>
  </PageTemplate>;
}
