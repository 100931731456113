export class LocalStorage {
  static save(itemIndex: string, value: any): void {
    localStorage.setItem(itemIndex, JSON.stringify(value));
  }

  static get(itemIndex: string): any {
    const response = localStorage.getItem(itemIndex);

    return response === null ? null : JSON.parse(response);
  }

  static remove(itemIndex: string): void {
    localStorage.removeItem(itemIndex);
  }

  static isset(itemIndex: string): boolean {
    return localStorage.getItem(itemIndex) !== null;
  }
}
