import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {API_URL, ApiManager} from "../Services/ApiManager";
import {UserContext, UserContextInterface} from "../Contexts/UserContext";

const GET_ACCOUNTS_URL = API_URL+'/accounts';
const RESET_PASSWORD_REQUEST_URL = API_URL+'/reset-password-request';

interface Account {
  email: string;
  firstname: string;
  lastname: string;
}

interface AccountToReset extends Account {
  password: string;
}

interface AccountsTyped {
  accounts: Account[];
  accountsToReset: AccountToReset[];
}

export const AccountsPage = () => {
  const userContext = useContext<UserContextInterface>(UserContext);

  const [accounts, setAccounts] = useState<AccountsTyped>();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchAccounts() {
    try {
      setIsLoading(() => true);
      const response =  await ApiManager.get(GET_ACCOUNTS_URL);
      setAccounts(() => response.data);
      setIsLoading(() => false);
    } catch(error: any) {
      console.error(`Error while fetching accounts:`, error);
      setIsLoading(() => false);
    }
  }

  useEffect(() => {
    fetchAccounts();
  }, []);

  const copyLink = (account: AccountToReset) => {
    navigator.clipboard.writeText(generateResetLink(account));
  };

  const generateResetLink = (account: AccountToReset) => {
    return `http://${window.location.hostname}/reset-password?email=${account.email}&password=${account.password}`;
  }

  async function sendResetPasswordRequest(email: string) {
    try {
      await ApiManager.post(RESET_PASSWORD_REQUEST_URL, {email});
      fetchAccounts();
    } catch (e: any) {}
  }

  return <PageTemplate selectedMenu={'accounts'}>
    <div className={`mb-[30px] flex items-center`}>
      <div className={`text-[30px]`}>Accounts</div>
      <Link to={'/accounts/create'}><div className={`ml-[20px] p-[10px] border rounded-xl`}>
        Create
      </div></Link>
    </div>
    <div>
      {isLoading ? <div>Loading...</div>: null}
      {!isLoading ? <div>
        <div className={`mb-[20px] underline`}>
          Validated accounts:
        </div>
        <div className={`pl-[10px]`}>
          {accounts?.accounts?.map((account: Account, index: number) => <div key={`account-${index}`}>
            {account.firstname} {account.lastname} {userContext.user?.user.email !== account.email ? <span className={`cursor-pointer`} onClick={() => sendResetPasswordRequest(account.email)}>[Reset password]</span> : ''}
          </div>)}
        </div>
        <div className={`my-[20px] underline`}>
          Uncompleted accounts:
        </div>
        <div className={`pl-[10px]`}>
          {accounts?.accountsToReset?.map((account: AccountToReset, index: number) => <div key={`account-to-reset-${index}`}>
            {account.firstname} {account.lastname} [<span className={`cursor-pointer`} onClick={() => copyLink(account)}>Copy link</span>]
          </div>)}
        </div>
      </div>: null}
    </div>
  </PageTemplate>;
}
