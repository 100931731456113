import {useState} from "react";
import {FilterInterface} from "./RetailersPage";
import { useComponentVisible } from "../../Hooks/ComponentVisibleHook";

interface FilterBlockInterface {
  title: string;
  filterName: string;
  handleClickCheckbox: (e: any) => void;
  currentFilterValue: string[];
  choices: FilterInterface[];
  customClass?: string;
}
export const FilterBlock = ({title, filterName, handleClickCheckbox, currentFilterValue, choices, customClass = 'w-[100px]'}: FilterBlockInterface) => {
  let {
    ref,
    isVisible,
    toggleVisibility
  } = useComponentVisible(false);

  const [isOpened, setIsOpened] = useState(false);

  return <div ref={ref} className={`h-[40px] ml-[10px] ${customClass}`}>
    <div onClick={() => toggleVisibility()} className={`cursor-pointer border-[1px] h-full flex items-center justify-center rounded-xl ${currentFilterValue.length > 0 ? 'text-white bg-black' : ''}`}>
      {title}
    </div>
    {isVisible ? <div className={`z-30 absolute ${currentFilterValue.length > 0 ? 'text-white bg-black' : ' bg-white'} border-l-[1px] border-r-[1px] border-b-[1px] mt-[-10px] pt-[10px] rounded-b-2xl ${customClass}`}>
      {choices.map((choice: FilterInterface, index: number) => <div className={`h-[40px] flex items-center border-t-[1px] px-[10px]`} key={`filter-caa_status-${index}`}>
        <input type={'checkbox'} name={filterName} value={choice.value} onClick={handleClickCheckbox} checked={currentFilterValue.includes(choice.value)} onChange={() => {}}/> <span className={`block ml-[10px]`}>{choice.label}</span>
      </div>)}
    </div> : null}
  </div>;
};
