import {UserContextHook, UserContextProvider} from "./Contexts/UserContext";
import {Router} from "./Router";
import {NotificationContextHook, NotificationContextProvider} from './Contexts/ToasterNotificationContext';

function App() {
  const [user, dispatchUser] = UserContextHook();
  const [notifications, dispatchNotifications] = NotificationContextHook();

  return (
    // @ts-ignore
    <UserContextProvider value={{user, dispatchUser}}>
      {/*// @ts-ignore*/}
      <NotificationContextProvider value={{notifications, dispatchNotifications}}>
        <Router/>
      </NotificationContextProvider>
    </UserContextProvider>
  );
}

export default App;
