interface LabelMapInterface {
  [name: string]: string;
}

const labelMap: LabelMapInterface = {
  user_not_found: 'User not found.',
  incorrect_password: 'Incorrect password.',
  user_needs_password_reset: 'Password reset is needed for this account.',
  user_password_set: 'Your new password is now set.',
  user_not_in_reset_mode: 'Your account is not in reset mode',
  form_criterion_type_alphanumeric: 'Alphanumeric format',
  form_criterion_type_email: 'Email format',
  form_criterion_minLength: 'At least {value} character(s)',
  form_criterion_maxLength: 'Maximum of {value} character(s)',
  form_criterion_atLeastOneSpecialChar: 'At least one special character',
  form_criterion_atLeastOneUpperCase: 'At least one upper case letter',
  form_criterion_atLeastOneLowerCase: 'At least one lower case letter',
  form_criterion_atLeastOneNumber: 'At least one number',
  form_criterion_blank: 'Cannot be blank',
  form_criterion_required: 'This field is required',
  check_form_inputs: 'Please check form input(s)',
  email_used: 'Email already in use',
  account_create_success: 'Account successfully created',
  incorrect_temporary_password: `Wrong token for password change, please contact an administrator for a correct link`,
};

export const getLabel = (labelKey: string) => {
  return labelMap[labelKey] ?? labelKey;
};
