import {createContext, useState} from "react";
import {USER, UserCache} from "../Cache/UserCache";

export const UPDATE_USER_ACTION = 'UPDATE_USER';
export const REMOVE_USER_ACTION = 'REMOVE_USER_ACTION';

const defaultUserContext = {
  user: undefined,
  dispatchUser: (actionType: string, payload?: USER) => {}
};

export const UserContext = createContext<UserContextInterface>(defaultUserContext);
export const UserContextProvider = UserContext.Provider;

export interface UserContextInterface {
  user?: USER,
  dispatchUser: (actionType: string, payload?: USER) => void;
}

export const UserContextHook = () => {
  const userCache = UserCache.get();

  const [user, setUser] = useState(userCache ?? undefined);
  const dispatchUser = (actionType: string, payload?: USER) => {
    switch (actionType) {
      case UPDATE_USER_ACTION:
        UserCache.save(payload);
        setUser(payload);
        break;
      case REMOVE_USER_ACTION:
        UserCache.remove();
        setUser(undefined);
        break;
    }
  };

  return [user, dispatchUser];
};
