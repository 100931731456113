import {useEffect, useState} from "react";
import moment from "moment";
import {PageTemplate} from "../Components/PageTemplate/PageTemplate";
import {API_URL, ApiManager} from "../Services/ApiManager";
import {ReactComponent as WarningIcon} from '../Assets/Icons/warning_triangle.svg';

type BRAND_NAMES = 'igraal' | 'pouch' | 'shoop' | 'cuponation';
type COUNTRIES = 'fr' | 'de' | 'es' | 'uk' | 'br';

const SYNCHRONIZE_RETAILERS_URL = API_URL+'/tools/synchronize/retailers/{brandName}';
const SYNCHRONIZE_REVENUES_URL = API_URL+'/tools/synchronize/revenues/{brandName}';
const SYNCHRONIZE_CAA_CLICKS_URL = API_URL+'/tools/synchronize/caaClicks/{brandName}';
const SYNCHRONIZE_EXTENSIONS_URL = API_URL+'/tools/synchronize/extensions/{brandName}/{country}';
const DETAILS_URL = API_URL+'/tools/details';

export const ToolsPage = () => {
  const [fetchingRetailers, setFetchingRetailers] = useState<{[name: string]: boolean}>({
    igraal: false,
    pouch: false,
    shoop: false,
    cuponation: false,
  });

  const [fetchingRevenues, setFetchingRevenues] = useState<{[name: string]: boolean}>({
    'igraal': false,
    'pouch': false,
    'shoop': false,
    'cuponation': false
  });

  const [fetchingCaaClicks, setFetchingCaaClicks] = useState<{[name: string]: boolean}>({
    'igraal': false,
    'pouch': false,
    'shoop': false,
    'cuponation': false
  });

  const [syncingExtensions, setSyncingExtensions] = useState<{[name: string]: boolean}>({
    'igraal-fr': false,
    'igraal-de': false,
    'igraal-es': false,
    'pouch-uk': false,
    'shoop-de': false,
    'cuponation-br': false
  });

  const [details, setDetails] = useState<any>();

  const fetchRetailers = (brandName: BRAND_NAMES) => {
    if (fetchingRetailers[brandName]) {
      return;
    }

    setFetchingRetailers(() => { return {...fetchingRetailers, [brandName]: true} });
    (async function () {
      try {
        const response = await ApiManager.get(SYNCHRONIZE_RETAILERS_URL.replace('{brandName}', brandName));

        setFetchingRetailers(() => { return {...fetchingRetailers, [brandName]: false} });
        await fetchDetails();
      } catch (e: any) {
        setFetchingRetailers(() => { return {...fetchingRetailers, [brandName]: false} });
        console.error(`Error while trying to synchronize brand ${brandName}`, e);
      }
    })();
  };

  const fetchRevenues = (brandName: BRAND_NAMES) => {
    if (fetchingRevenues[brandName]) {
      return;
    }

    setFetchingRevenues(() => { return {...fetchingRevenues, [brandName]: true} });
    (async function () {
      try {
        await ApiManager.get(SYNCHRONIZE_REVENUES_URL.replace('{brandName}', brandName));

        setFetchingRevenues(() => { return {...fetchingRevenues, [brandName]: false} });
        await fetchDetails();
      } catch (e: any) {
        setFetchingRevenues(() => { return {...fetchingRevenues, [brandName]: false} });
        console.error(`Error while trying to synchronize brand ${brandName} revenues`, e);
      }
    })();
  };

  const fetchCaaClicks = (brandName: BRAND_NAMES) => {
    if (fetchingCaaClicks[brandName]) {
      return;
    }

    setFetchingCaaClicks(() => { return {...fetchCaaClicks, [brandName]: true} });
    (async function () {
      try {
        await ApiManager.get(SYNCHRONIZE_CAA_CLICKS_URL.replace('{brandName}', brandName));

        setFetchingCaaClicks(() => { return {...fetchingCaaClicks, [brandName]: false} });
        await fetchDetails();
      } catch (e: any) {
        setFetchingCaaClicks(() => { return {...fetchingCaaClicks, [brandName]: false} });
        console.error(`Error while trying to synchronize brand ${brandName} CAA impressions`, e);
      }
    })();
  };

  const syncExtensions = (brandName: BRAND_NAMES, country: COUNTRIES) => {
    const index = `${brandName}-${country}`;
    if (syncingExtensions[index]) {
      return;
    }

    setSyncingExtensions(() => { return {...syncingExtensions, [index]: true} });
    (async function () {
      try {
        const response = await ApiManager.get(SYNCHRONIZE_EXTENSIONS_URL.replace('{brandName}', brandName).replace('{country}', country));

        setSyncingExtensions(() => { return {...syncingExtensions, [index]: false} });
        await fetchDetails();
      } catch (e: any) {
        setSyncingExtensions(() => { return {...syncingExtensions, [index]: false} });
        console.error(`Error while trying to synchronize brand ${index}`, e);
      }
    })();
  };

  const fetchDetails = async () => {
    try {
      const response = await ApiManager.get(DETAILS_URL);

      setDetails(() => response.data);
    } catch(e: any) {
      console.error(`Error while fetching details`, e);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [])

  const getLastSyncedRetailersDate = (brandName: string) => {
    if (details === undefined || details.synchronize === undefined || details.synchronize.retailers === undefined || undefined === details.synchronize.retailers[brandName] || undefined === details.synchronize.retailers[brandName].date) {
      return 'Unknown last synced date';
    }

    return moment(details.synchronize.retailers[brandName].date).format('YYYY-MM-DD hh:mm:ss');
  }

  const getLastSyncedRevenuesDate = (brandName: string) => {
    if (undefined === details?.synchronize?.revenues?.[`${brandName}`]?.date) {
      return 'Unknown last synced date';
    }

    return moment(details.synchronize.revenues[`${brandName}`].date).format('YYYY-MM-DD hh:mm:ss');
  }
  const getLastSyncedCaaClicksDate = (brandName: string) => {
    if (undefined === details?.synchronize?.caaClicks?.[`${brandName}`]?.date) {
      return 'Unknown last synced date';
    }

    return moment(details.synchronize.caaClicks[`${brandName}`].date).format('YYYY-MM-DD hh:mm:ss');
  }

  const getLastSyncedExtensionsDate = (brandName: string, country: string) => {
    if (details === undefined || details.synchronize === undefined || details.synchronize.extensions === undefined || undefined === details.synchronize.extensions[`${brandName}-${country}`] || undefined === details.synchronize.extensions[`${brandName}-${country}`].date) {
      return 'Unknown last synced date';
    }

    return moment(details.synchronize.extensions[`${brandName}-${country}`].date).format('YYYY-MM-DD hh:mm:ss');
  }

  return <PageTemplate>
    <div className={`text-[30px] mb-[30px]`}>
      Tools
    </div>
    <div>
      <div>
        <div className={`text-[25px] flex items-center gap-[10px]`}>
          Retailers synchronization
        </div>
        <div className={`italic`}>Fetch retailers using the related API url & save</div>
      </div>
      <div className={`my-[30px]`}>
        <div className={`flex items-center gap-[10px] my-[10px]`}>
          <div>Launch for iGraal</div>
          <div>FR, DE, ES</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRetailers('igraal')}>Execute</button>
          <div>{fetchingRetailers.igraal ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRetailersDate('igraal')})`}</div>
        </div>
        <div className={`flex items-center gap-[10px] my-[10px]`}>
          <div>Launch for Pouch</div>
          <div>UK</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRetailers('pouch')}>Execute</button>
          <div>{fetchingRetailers.pouch ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRetailersDate('pouch')})`}</div>
        </div>
        <div className={`flex items-center gap-[10px] my-[10px]`}>
          <div>Launch for Shoop</div>
          <div>DE</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRetailers('shoop')}>Execute</button>
          <div>{fetchingRetailers.shoop ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRetailersDate('shoop')})`}</div>
        </div>
        <div className={`flex items-center gap-[10px] my-[10px]`}>
          <div>Launch for Cuponation</div>
          <div>BR</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRetailers('cuponation')}>Execute</button>
          <div>{fetchingRetailers.cuponation ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRetailersDate('cuponation')})`}</div>
        </div>
      </div>
      <div>
        <div className={`text-[25px]`}>
          Retailers revenues
        </div>
      </div>
      <div className={`my-[30px]`}>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for iGraal</div>
          <div>FR, DE, ES</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRevenues('igraal')}>Execute</button>
          <div>{fetchingRevenues['igraal'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRevenuesDate('igraal')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Pouch</div>
          <div>UK</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRevenues('pouch')}>Execute</button>
          <div>{fetchingRevenues['pouch'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRevenuesDate('pouch')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Shoop</div>
          <div>DE</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRevenues('shoop')}>Execute</button>
          <div>{fetchingRevenues['shoop'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRevenuesDate('shoop')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Cuponation</div>
          <div>BR</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchRevenues('cuponation')}>Execute</button>
          <div>{fetchingRevenues['cuponation'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedRevenuesDate('cuponation')})`}</div>
        </div>
      </div>
      <div>
        <div className={`text-[25px]`}>
          Retailers CAA impressions
        </div>
      </div>
      <div className={`my-[30px]`}>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Pouch</div>
          <div>UK</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchCaaClicks('pouch')}>Execute</button>
          <div>{fetchingCaaClicks['pouch'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedCaaClicksDate('pouch')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Igraal</div>
          <div>FR, DE, ES</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => fetchCaaClicks('igraal')}>Execute</button>
          <div>{fetchingCaaClicks['igraal'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedCaaClicksDate('igraal')})`}</div>
        </div>
      </div>
      <div>
        <div className={`text-[25px]`}>
          Extension synchonization (features + configurations)
        </div>
      </div>
      <div className={`my-[30px]`}>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for iGraal</div>
          <div>FR</div>
          <button className={`border rounded px-[10px] py-[5px] mx-[10px] hover:bg-slate-200`} onClick={() => syncExtensions('igraal', 'fr')}>Execute</button>
          <div>{syncingExtensions['igraal-fr'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('igraal', 'fr')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for iGraal</div>
          <div>DE</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => syncExtensions('igraal', 'de')}>Execute</button>
          <div>{syncingExtensions['igraal-de'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('igraal', 'de')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for iGraal</div>
          <div>ES</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => syncExtensions('igraal', 'es')}>Execute</button>
          <div>{syncingExtensions['igraal-es'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('igraal', 'es')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Pouch</div>
          <div>UK</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => syncExtensions('pouch', 'uk')}>Execute</button>
          <div>{syncingExtensions['pouch-uk'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('pouch', 'uk')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Shoop</div>
          <div>DE</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => syncExtensions('shoop', 'de')}>Execute</button>
          <div>{syncingExtensions['shoop-de'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('shoop', 'de')})`}</div>
        </div>
        <div className={`flex items-center my-[10px] gap-[10px]`}>
          <div>Launch for Cuponation</div>
          <div>BR</div>
          <button className={`border rounded px-[10px] py-[5px] hover:bg-slate-200`} onClick={() => syncExtensions('cuponation', 'br')}>Execute</button>
          <div>{syncingExtensions['cuponation-br'] ? `(Loading...)` : `(Last synchronization: ${getLastSyncedExtensionsDate('cuponation', 'br')})`}</div>
        </div>
      </div>
    </div>
  </PageTemplate>;
}
