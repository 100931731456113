import {createContext, useState, ReactElement} from "react";
import moment from "moment";

export const TOASTER_TIMER = 5000;

export const ADD_NOTIFICATION_ACTION = 'ADD_NOTIFICATION_ACTION';
export const CLEAR_NOTIFICATIONS_ACTION = 'CLEAR_NOTIFICATIONS_ACTION';

export const NOTIFICATION_STATUS_SUCCESS = 'SUCCESS';
export const NOTIFICATION_STATUS_FAIL = 'FAIL';

export const NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED = 'NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED';
export const NOTIFICATION_OPERATION_MERCHANT_STATUSES_UPDATED = 'NOTIFICATION_OPERATION_MERCHANT_STATUSES_UPDATED';

interface NotificationPayloadInterface {
  status: string;
  operation: string;
  inputs: any;
};

export interface NotificationBlockInterface {
  status: string;
  title: string;
  description: string|ReactElement;
  dateTime: any;
};

export interface NotificationContextInterface {
  notifications: NotificationBlockInterface[];
  dispatchNotifications: (actionType: string, payload?: NotificationPayloadInterface) => void;
};

const defaultNotificationContext = {
  notifications: [],
  dispatchNotifications: (actionType: string, payload?: NotificationPayloadInterface) => {}
};

export const NotificationContext = createContext<NotificationContextInterface>(defaultNotificationContext);
export const NotificationContextProvider = NotificationContext.Provider;

export const NotificationContextHook = () => {
  const [notifications, setNotifications] = useState<NotificationBlockInterface[]>([]);
  const dispatchNotifications = (actionType: string, payload?: NotificationPayloadInterface) => {
    switch (actionType) {
      case ADD_NOTIFICATION_ACTION:
        if (payload !== undefined) {
          let title: string = '';
          let description: string = '';
          switch (payload.operation) {
            case NOTIFICATION_OPERATION_MERCHANT_FEATURES_UPDATED:
              title = `Retailer features updated`;
              description = `One or more features have been updated for ${payload.inputs.merchantName}`;
              break;
            case NOTIFICATION_OPERATION_MERCHANT_STATUSES_UPDATED:
              title = `Retailer statuses updated`;
              description = `One or more statuses/comment have been updated for ${payload.inputs.merchantName}`;
              break;
          }
          if (title.length > 0) {
            setNotifications(() => ([...notifications, {status: payload.status, title, description, dateTime: moment()}]));
          }
        }
        break;
      case CLEAR_NOTIFICATIONS_ACTION:
        if (notifications.length > 0) {
          const filteredNotifications: NotificationBlockInterface[] = notifications.filter((notification: NotificationBlockInterface) => moment().diff(notification.dateTime) < TOASTER_TIMER);
          setNotifications(() => ([...filteredNotifications]));
        }
        break;
    }
  };

  return [notifications, dispatchNotifications];
};
